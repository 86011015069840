import { MediaConnectionEventNames, LocalStreamEventNames, RoapMediaConnection, } from '@webex/internal-media-core';
import { createMachine, interpret } from 'xstate';
import { v4 as uuid } from 'uuid';
import { EffectEvent } from '@webex/web-media-effects';
import { RtcMetrics } from '@webex/internal-plugin-metrics';
import { ERROR_LAYER, ERROR_TYPE } from '../../Errors/types';
import { handleCallErrors, modifySdpForIPv4, parseMediaQualityStatistics, serviceErrorCodeHandler, uploadLogs, } from '../../common/Utils';
import { ALLOWED_SERVICES, CallDirection, HTTP_METHODS, } from '../../common/types';
import { createCallError } from '../../Errors/catalog/CallError';
import { CALL_ENDPOINT_RESOURCE, CALL_FILE, CALL_HOLD_SERVICE, CALL_STATUS_RESOURCE, CALL_TRANSFER_SERVICE, CALLING_USER_AGENT, CALLS_ENDPOINT_RESOURCE, CISCO_DEVICE_URL, DEFAULT_LOCAL_CALL_ID, DEFAULT_SESSION_TIMER, DEVICES_ENDPOINT_RESOURCE, HOLD_ENDPOINT, ICE_CANDIDATES_TIMEOUT, INITIAL_SEQ_NUMBER, MEDIA_ENDPOINT_RESOURCE, NOISE_REDUCTION_EFFECT, RESUME_ENDPOINT, SPARK_USER_AGENT, SUPPLEMENTARY_SERVICES_TIMEOUT, TRANSFER_ENDPOINT, } from '../constants';
import SDKConnector from '../../SDKConnector';
import { Eventing } from '../../Events/impl';
import { CALL_EVENT_KEYS, MEDIA_CONNECTION_EVENT_KEYS, MOBIUS_MIDCALL_STATE, SUPPLEMENTARY_SERVICES, } from '../../Events/types';
import { DisconnectCause, DisconnectCode, MidCallEventType, MobiusCallState, MUTE_TYPE, RoapScenario, TransferType, } from './types';
import log from '../../Logger';
import { createCallerId } from './CallerId';
import { METRIC_TYPE, METRIC_EVENT, TRANSFER_ACTION } from '../../Metrics/types';
import { getMetricManager } from '../../Metrics';
import { SERVICES_ENDPOINT } from '../../common/constants';
export class Call extends Eventing {
    sdkConnector;
    webex;
    destination;
    direction;
    callId;
    correlationId;
    deviceId;
    lineId;
    disconnectReason;
    callStateMachine;
    mediaStateMachine;
    seq;
    mediaConnection;
    earlyMedia;
    connected;
    mediaInactivity;
    callerInfo;
    localRoapMessage;
    mobiusUrl;
    remoteRoapMessage;
    deleteCb;
    callerId;
    sessionTimer;
    supplementaryServicesTimer;
    muted;
    held;
    metricManager;
    broadworksCorrelationInfo;
    serviceIndicator;
    mediaNegotiationCompleted;
    receivedRoapOKSeq;
    localAudioStream;
    rtcMetrics;
    isMuted() {
        return this.muted;
    }
    isConnected() {
        return this.connected;
    }
    isHeld() {
        return this.held;
    }
    constructor(activeUrl, webex, direction, deviceId, lineId, deleteCb, indicator, destination) {
        super();
        this.destination = destination;
        this.direction = direction;
        this.sdkConnector = SDKConnector;
        this.deviceId = deviceId;
        this.serviceIndicator = indicator;
        this.lineId = lineId;
        if (!this.sdkConnector.getWebex()) {
            SDKConnector.setWebex(webex);
        }
        this.webex = this.sdkConnector.getWebex();
        this.metricManager = getMetricManager(this.webex, this.serviceIndicator);
        this.callId = `${DEFAULT_LOCAL_CALL_ID}_${uuid()}`;
        this.correlationId = uuid();
        this.deleteCb = deleteCb;
        this.connected = false;
        this.mediaInactivity = false;
        this.held = false;
        this.earlyMedia = false;
        this.callerInfo = {};
        this.localRoapMessage = {};
        this.mobiusUrl = activeUrl;
        this.receivedRoapOKSeq = 0;
        this.mediaNegotiationCompleted = false;
        log.info(`Webex Calling Url:- ${this.mobiusUrl}`, {
            file: CALL_FILE,
            method: 'constructor',
        });
        this.seq = INITIAL_SEQ_NUMBER;
        this.callerId = createCallerId(webex, (callerInfo) => {
            this.callerInfo = callerInfo;
            const emitObj = {
                correlationId: this.correlationId,
                callerId: this.callerInfo,
            };
            this.emit(CALL_EVENT_KEYS.CALLER_ID, emitObj);
        });
        this.remoteRoapMessage = null;
        this.disconnectReason = { code: DisconnectCode.NORMAL, cause: DisconnectCause.NORMAL };
        this.rtcMetrics = new RtcMetrics(this.webex, { callId: this.callId }, this.correlationId);
        const callMachine = createMachine({
            schema: {
                context: {},
                events: {},
            },
            id: 'call-state',
            initial: 'S_IDLE',
            context: {},
            states: {
                S_IDLE: {
                    on: {
                        E_RECV_CALL_SETUP: {
                            target: 'S_RECV_CALL_SETUP',
                            actions: ['incomingCallSetup'],
                        },
                        E_SEND_CALL_SETUP: {
                            target: 'S_SEND_CALL_SETUP',
                            actions: ['outgoingCallSetup'],
                        },
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_RECV_CALL_SETUP: {
                    after: {
                        10000: {
                            target: 'S_CALL_CLEARED',
                            actions: ['triggerTimeout'],
                        },
                    },
                    on: {
                        E_SEND_CALL_ALERTING: {
                            target: 'S_SEND_CALL_PROGRESS',
                            actions: ['outgoingCallAlerting'],
                        },
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_SEND_CALL_SETUP: {
                    after: {
                        10000: {
                            target: 'S_CALL_CLEARED',
                            actions: ['triggerTimeout'],
                        },
                    },
                    on: {
                        E_RECV_CALL_PROGRESS: {
                            target: 'S_RECV_CALL_PROGRESS',
                            actions: ['incomingCallProgress'],
                        },
                        E_RECV_CALL_CONNECT: {
                            target: 'S_RECV_CALL_CONNECT',
                            actions: ['incomingCallConnect'],
                        },
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_RECV_CALL_PROGRESS: {
                    after: {
                        60000: {
                            target: 'S_CALL_CLEARED',
                            actions: ['triggerTimeout'],
                        },
                    },
                    on: {
                        E_RECV_CALL_CONNECT: {
                            target: 'S_RECV_CALL_CONNECT',
                            actions: ['incomingCallConnect'],
                        },
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_RECV_CALL_PROGRESS: {
                            target: 'S_RECV_CALL_PROGRESS',
                            actions: ['incomingCallProgress'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_SEND_CALL_PROGRESS: {
                    after: {
                        60000: {
                            target: 'S_CALL_CLEARED',
                            actions: ['triggerTimeout'],
                        },
                    },
                    on: {
                        E_SEND_CALL_CONNECT: {
                            target: 'S_SEND_CALL_CONNECT',
                            actions: ['outgoingCallConnect'],
                        },
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_RECV_CALL_CONNECT: {
                    after: {
                        10000: {
                            target: 'S_CALL_CLEARED',
                            actions: ['triggerTimeout'],
                        },
                    },
                    on: {
                        E_CALL_ESTABLISHED: {
                            target: 'S_CALL_ESTABLISHED',
                            actions: ['callEstablished'],
                        },
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_SEND_CALL_CONNECT: {
                    after: {
                        10000: {
                            target: 'S_CALL_CLEARED',
                            actions: ['triggerTimeout'],
                        },
                    },
                    on: {
                        E_CALL_ESTABLISHED: {
                            target: 'S_CALL_ESTABLISHED',
                            actions: ['callEstablished'],
                        },
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_CALL_HOLD: {
                    on: {
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_CALL_ESTABLISHED: {
                            target: 'S_CALL_ESTABLISHED',
                            actions: ['callEstablished'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_CALL_RESUME: {
                    on: {
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_CALL_ESTABLISHED: {
                            target: 'S_CALL_ESTABLISHED',
                            actions: ['callEstablished'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_CALL_ESTABLISHED: {
                    on: {
                        E_CALL_HOLD: {
                            target: 'S_CALL_HOLD',
                            actions: ['initiateCallHold'],
                        },
                        E_CALL_RESUME: {
                            target: 'S_CALL_RESUME',
                            actions: ['initiateCallResume'],
                        },
                        E_RECV_CALL_DISCONNECT: {
                            target: 'S_RECV_CALL_DISCONNECT',
                            actions: ['incomingCallDisconnect'],
                        },
                        E_SEND_CALL_DISCONNECT: {
                            target: 'S_SEND_CALL_DISCONNECT',
                            actions: ['outgoingCallDisconnect'],
                        },
                        E_CALL_ESTABLISHED: {
                            target: 'S_CALL_ESTABLISHED',
                            actions: ['callEstablished'],
                        },
                        E_UNKNOWN: {
                            target: 'S_UNKNOWN',
                            actions: ['unknownState'],
                        },
                    },
                },
                S_RECV_CALL_DISCONNECT: {
                    on: {
                        E_CALL_CLEARED: 'S_CALL_CLEARED',
                    },
                },
                S_SEND_CALL_DISCONNECT: {
                    on: {
                        E_CALL_CLEARED: 'S_CALL_CLEARED',
                    },
                },
                S_UNKNOWN: {
                    on: {
                        E_CALL_CLEARED: 'S_CALL_CLEARED',
                    },
                },
                S_ERROR: {
                    on: {
                        E_CALL_CLEARED: 'S_CALL_CLEARED',
                    },
                },
                S_CALL_CLEARED: {
                    type: 'final',
                },
            },
        }, {
            actions: {
                incomingCallSetup: (context, event) => this.handleIncomingCallSetup(event),
                outgoingCallSetup: (context, event) => this.handleOutgoingCallSetup(event),
                incomingCallProgress: (context, event) => this.handleIncomingCallProgress(event),
                outgoingCallAlerting: (context, event) => this.handleOutgoingCallAlerting(event),
                incomingCallConnect: (context, event) => this.handleIncomingCallConnect(event),
                outgoingCallConnect: (context, event) => this.handleOutgoingCallConnect(event),
                callEstablished: (context, event) => this.handleCallEstablished(event),
                initiateCallHold: (context, event) => this.handleCallHold(event),
                initiateCallResume: (context, event) => this.handleCallResume(event),
                incomingCallDisconnect: (context, event) => this.handleIncomingCallDisconnect(event),
                outgoingCallDisconnect: (context, event) => this.handleOutgoingCallDisconnect(event),
                unknownState: (context, event) => this.handleUnknownState(event),
                triggerTimeout: () => this.handleTimeout(),
            },
        });
        const mediaMachine = createMachine({
            schema: {
                context: {},
                events: {},
            },
            id: 'roap-state',
            initial: 'S_ROAP_IDLE',
            context: {},
            states: {
                S_ROAP_IDLE: {
                    on: {
                        E_RECV_ROAP_OFFER_REQUEST: {
                            target: 'S_RECV_ROAP_OFFER_REQUEST',
                            actions: ['incomingRoapOfferRequest'],
                        },
                        E_RECV_ROAP_OFFER: {
                            target: 'S_RECV_ROAP_OFFER',
                            actions: ['incomingRoapOffer'],
                        },
                        E_SEND_ROAP_OFFER: {
                            target: 'S_SEND_ROAP_OFFER',
                            actions: ['outgoingRoapOffer'],
                        },
                    },
                },
                S_RECV_ROAP_OFFER_REQUEST: {
                    on: {
                        E_SEND_ROAP_OFFER: {
                            target: 'S_SEND_ROAP_OFFER',
                            actions: ['outgoingRoapOffer'],
                        },
                        E_ROAP_OK: {
                            target: 'S_ROAP_OK',
                            actions: ['roapEstablished'],
                        },
                        E_ROAP_ERROR: {
                            target: 'S_ROAP_ERROR',
                            actions: ['roapError'],
                        },
                    },
                },
                S_RECV_ROAP_OFFER: {
                    on: {
                        E_SEND_ROAP_ANSWER: {
                            target: 'S_SEND_ROAP_ANSWER',
                            actions: ['outgoingRoapAnswer'],
                        },
                        E_ROAP_OK: {
                            target: 'S_ROAP_OK',
                            actions: ['roapEstablished'],
                        },
                        E_ROAP_ERROR: {
                            target: 'S_ROAP_ERROR',
                            actions: ['roapError'],
                        },
                    },
                },
                S_SEND_ROAP_OFFER: {
                    on: {
                        E_RECV_ROAP_ANSWER: {
                            target: 'S_RECV_ROAP_ANSWER',
                            actions: ['incomingRoapAnswer'],
                        },
                        E_SEND_ROAP_ANSWER: {
                            target: 'S_SEND_ROAP_ANSWER',
                            actions: ['outgoingRoapAnswer'],
                        },
                        E_SEND_ROAP_OFFER: {
                            target: 'S_SEND_ROAP_OFFER',
                            actions: ['outgoingRoapOffer'],
                        },
                        E_ROAP_ERROR: {
                            target: 'S_ROAP_ERROR',
                            actions: ['roapError'],
                        },
                    },
                },
                S_RECV_ROAP_ANSWER: {
                    on: {
                        E_ROAP_OK: {
                            target: 'S_ROAP_OK',
                            actions: ['roapEstablished'],
                        },
                        E_ROAP_ERROR: {
                            target: 'S_ROAP_ERROR',
                            actions: ['roapError'],
                        },
                    },
                },
                S_SEND_ROAP_ANSWER: {
                    on: {
                        E_RECV_ROAP_OFFER_REQUEST: {
                            target: 'S_RECV_ROAP_OFFER_REQUEST',
                            actions: ['incomingRoapOfferRequest'],
                        },
                        E_RECV_ROAP_OFFER: {
                            target: 'S_RECV_ROAP_OFFER',
                            actions: ['incomingRoapOffer'],
                        },
                        E_ROAP_OK: {
                            target: 'S_ROAP_OK',
                            actions: ['roapEstablished'],
                        },
                        E_SEND_ROAP_ANSWER: {
                            target: 'S_SEND_ROAP_ANSWER',
                            actions: ['outgoingRoapAnswer'],
                        },
                        E_ROAP_ERROR: {
                            target: 'S_ROAP_ERROR',
                            actions: ['roapError'],
                        },
                    },
                },
                S_ROAP_OK: {
                    on: {
                        E_RECV_ROAP_OFFER_REQUEST: {
                            target: 'S_RECV_ROAP_OFFER_REQUEST',
                            actions: ['incomingRoapOfferRequest'],
                        },
                        E_RECV_ROAP_OFFER: {
                            target: 'S_RECV_ROAP_OFFER',
                            actions: ['incomingRoapOffer'],
                        },
                        E_ROAP_OK: {
                            target: 'S_ROAP_OK',
                            actions: ['roapEstablished'],
                        },
                        E_SEND_ROAP_OFFER: {
                            target: 'S_SEND_ROAP_OFFER',
                            actions: ['outgoingRoapOffer'],
                        },
                        E_ROAP_ERROR: {
                            target: 'S_ROAP_ERROR',
                            actions: ['roapError'],
                        },
                        E_ROAP_TEARDOWN: {
                            target: 'S_ROAP_TEARDOWN',
                        },
                    },
                },
                S_ROAP_ERROR: {
                    on: {
                        E_ROAP_TEARDOWN: {
                            target: 'S_ROAP_TEARDOWN',
                        },
                        E_RECV_ROAP_OFFER_REQUEST: {
                            target: 'S_RECV_ROAP_OFFER_REQUEST',
                            actions: ['incomingRoapOfferRequest'],
                        },
                        E_RECV_ROAP_OFFER: {
                            target: 'S_RECV_ROAP_OFFER',
                            actions: ['incomingRoapOffer'],
                        },
                        E_RECV_ROAP_ANSWER: {
                            target: 'S_RECV_ROAP_ANSWER',
                            actions: ['incomingRoapAnswer'],
                        },
                        E_ROAP_OK: {
                            target: 'S_ROAP_OK',
                            actions: ['roapEstablished'],
                        },
                    },
                },
                S_ROAP_TEARDOWN: {
                    type: 'final',
                },
            },
        }, {
            actions: {
                incomingRoapOffer: (context, event) => this.handleIncomingRoapOffer(context, event),
                incomingRoapAnswer: (context, event) => this.handleIncomingRoapAnswer(context, event),
                incomingRoapOfferRequest: (context, event) => this.handleIncomingRoapOfferRequest(context, event),
                outgoingRoapOffer: (context, event) => this.handleOutgoingRoapOffer(context, event),
                outgoingRoapAnswer: (context, event) => this.handleOutgoingRoapAnswer(context, event),
                roapEstablished: (context, event) => this.handleRoapEstablished(context, event),
                roapError: (context, event) => this.handleRoapError(context, event),
            },
        });
        this.callStateMachine = interpret(callMachine)
            .onTransition((state, event) => {
            log.log(`Call StateMachine:- state=${state.value}, event=${JSON.stringify(event.type)}`, {});
            if (state.value !== 'S_UNKNOWN') {
                this.metricManager.submitCallMetric(METRIC_EVENT.CALL, state.value.toString(), METRIC_TYPE.BEHAVIORAL, this.callId, this.correlationId, undefined);
            }
        })
            .start();
        this.mediaStateMachine = interpret(mediaMachine)
            .onTransition((state, event) => {
            log.log(`Media StateMachine:- state=${state.value}, event=${JSON.stringify(event.type)}`, {});
            if (state.value !== 'S_ROAP_ERROR') {
                this.metricManager.submitMediaMetric(METRIC_EVENT.MEDIA, state.value.toString(), METRIC_TYPE.BEHAVIORAL, this.callId, this.correlationId, this.localRoapMessage.sdp, this.remoteRoapMessage?.sdp, undefined);
            }
        })
            .start();
        this.muted = false;
    }
    handleIncomingCallSetup(event) {
        log.info(`handleIncomingCallSetup: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleIncomingCallSetup.name,
        });
        this.sendCallStateMachineEvt({ type: 'E_SEND_CALL_ALERTING' });
    }
    async handleOutgoingCallSetup(event) {
        log.info(`handleOutgoingCallSetup: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleOutgoingCallSetup.name,
        });
        const message = event.data;
        try {
            const response = await this.post(message);
            log.log(`handleOutgoingCallSetup: Response: ${JSON.stringify(response)}`, {
                file: CALL_FILE,
                method: this.handleOutgoingCallSetup.name,
            });
            log.log(`handleOutgoingCallSetup: Response code: ${response.statusCode}`, {
                file: CALL_FILE,
                method: this.handleOutgoingCallSetup.name,
            });
            this.setCallId(response.body.callId);
        }
        catch (e) {
            log.warn('Failed to setup the call', {
                file: CALL_FILE,
                method: this.handleOutgoingCallSetup.name,
            });
            const errData = e;
            handleCallErrors((error) => {
                this.emit(CALL_EVENT_KEYS.CALL_ERROR, error);
                this.submitCallErrorMetric(error);
                this.sendCallStateMachineEvt({ type: 'E_UNKNOWN', data: errData });
            }, ERROR_LAYER.CALL_CONTROL, (interval) => undefined, this.getCorrelationId(), errData, this.handleOutgoingCallSetup.name, CALL_FILE);
            uploadLogs({
                correlationId: this.correlationId,
                callId: this.callId,
            });
        }
    }
    async handleCallHold(event) {
        log.info(`handleCallHold: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleCallHold.name,
        });
        try {
            const response = await this.postSSRequest(undefined, SUPPLEMENTARY_SERVICES.HOLD);
            log.log(`Response code: ${response.statusCode}`, {
                file: CALL_FILE,
                method: this.handleCallHold.name,
            });
            if (this.isHeld() === false) {
                this.supplementaryServicesTimer = setTimeout(async () => {
                    const errorContext = { file: CALL_FILE, method: this.handleCallHold.name };
                    log.warn('Hold response timed out', {
                        file: CALL_FILE,
                        method: this.handleCallHold.name,
                    });
                    const callError = createCallError('An error occurred while placing the call on hold. Wait a moment and try again.', errorContext, ERROR_TYPE.TIMEOUT, this.getCorrelationId(), ERROR_LAYER.CALL_CONTROL);
                    this.emit(CALL_EVENT_KEYS.HOLD_ERROR, callError);
                    this.submitCallErrorMetric(callError);
                }, SUPPLEMENTARY_SERVICES_TIMEOUT);
            }
        }
        catch (e) {
            log.warn('Failed to put the call on hold', {
                file: CALL_FILE,
                method: this.handleCallHold.name,
            });
            const errData = e;
            handleCallErrors((error) => {
                this.emit(CALL_EVENT_KEYS.HOLD_ERROR, error);
                this.submitCallErrorMetric(error);
                this.sendCallStateMachineEvt({ type: 'E_CALL_ESTABLISHED', data: errData });
            }, ERROR_LAYER.CALL_CONTROL, (interval) => undefined, this.getCorrelationId(), errData, this.handleOutgoingCallSetup.name, CALL_FILE);
            uploadLogs({
                correlationId: this.correlationId,
                callId: this.callId,
            });
        }
    }
    async handleCallResume(event) {
        log.info(`handleCallResume: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleCallResume.name,
        });
        try {
            const response = await this.postSSRequest(undefined, SUPPLEMENTARY_SERVICES.RESUME);
            log.log(`Response code: ${response.statusCode}`, {
                file: CALL_FILE,
                method: this.handleCallResume.name,
            });
            if (this.isHeld() === true) {
                this.supplementaryServicesTimer = setTimeout(async () => {
                    const errorContext = { file: CALL_FILE, method: this.handleCallResume.name };
                    log.warn('Resume response timed out', {
                        file: CALL_FILE,
                        method: this.handleCallResume.name,
                    });
                    const callError = createCallError('An error occurred while resuming the call. Wait a moment and try again.', errorContext, ERROR_TYPE.TIMEOUT, this.getCorrelationId(), ERROR_LAYER.CALL_CONTROL);
                    this.emit(CALL_EVENT_KEYS.RESUME_ERROR, callError);
                    this.submitCallErrorMetric(callError);
                }, SUPPLEMENTARY_SERVICES_TIMEOUT);
            }
        }
        catch (e) {
            log.warn('Failed to resume the call', {
                file: CALL_FILE,
                method: this.handleCallResume.name,
            });
            const errData = e;
            handleCallErrors((error) => {
                this.emit(CALL_EVENT_KEYS.RESUME_ERROR, error);
                this.submitCallErrorMetric(error);
                this.sendCallStateMachineEvt({ type: 'E_CALL_ESTABLISHED', data: errData });
            }, ERROR_LAYER.CALL_CONTROL, (interval) => undefined, this.getCorrelationId(), errData, this.handleOutgoingCallSetup.name, CALL_FILE);
            uploadLogs({
                correlationId: this.correlationId,
                callId: this.callId,
            });
        }
    }
    handleIncomingCallProgress(event) {
        log.info(`handleIncomingCallProgress: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleIncomingCallProgress.name,
        });
        const data = event.data;
        if (data?.callProgressData?.inbandMedia) {
            log.log('Inband media present. Setting Early Media flag', {
                file: CALL_FILE,
                method: this.handleIncomingCallProgress.name,
            });
            this.earlyMedia = true;
        }
        else {
            log.log('Inband media not present.', {
                file: CALL_FILE,
                method: this.handleIncomingCallProgress.name,
            });
        }
        if (data?.callerId) {
            log.info('Processing Caller-Id data', {
                file: CALL_FILE,
                method: this.handleIncomingCallProgress.name,
            });
            this.startCallerIdResolution(data.callerId);
        }
        this.emit(CALL_EVENT_KEYS.PROGRESS, this.correlationId);
    }
    handleIncomingRoapOfferRequest(context, event) {
        log.info(`handleIncomingRoapOfferRequest: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleIncomingRoapOfferRequest.name,
        });
        const message = event.data;
        if (!this.mediaConnection) {
            log.info('Media connection is not up, buffer the remote Offer Request for later handling', {
                file: CALL_FILE,
                method: this.handleIncomingRoapOfferRequest.name,
            });
            this.seq = message.seq;
            log.info(`Setting Sequence No: ${this.seq}`, {
                file: CALL_FILE,
                method: this.handleIncomingRoapOfferRequest.name,
            });
            this.remoteRoapMessage = message;
        }
        else if (this.receivedRoapOKSeq === message.seq - 2) {
            log.info('Waiting for Roap OK, buffer the remote Offer Request for later handling', {
                file: CALL_FILE,
                method: this.handleIncomingRoapOfferRequest.name,
            });
            this.remoteRoapMessage = message;
        }
        else {
            message.seq = this.seq + 1;
            this.seq = message.seq;
            this.mediaConnection.roapMessageReceived(message);
        }
    }
    async handleOutgoingCallAlerting(event) {
        log.info(`handleOutgoingCallAlerting: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleOutgoingCallAlerting.name,
        });
        try {
            const res = await this.patch(MobiusCallState.ALERTING);
            log.log(`PATCH response: ${res.statusCode}`, {
                file: CALL_FILE,
                method: this.handleOutgoingCallAlerting.name,
            });
        }
        catch (err) {
            log.warn('Failed to signal call progression', {
                file: CALL_FILE,
                method: this.handleOutgoingCallAlerting.name,
            });
            const errData = err;
            handleCallErrors((error) => {
                this.emit(CALL_EVENT_KEYS.CALL_ERROR, error);
                this.submitCallErrorMetric(error);
                this.sendCallStateMachineEvt({ type: 'E_UNKNOWN', data: errData });
            }, ERROR_LAYER.CALL_CONTROL, (interval) => undefined, this.getCorrelationId(), errData, this.handleOutgoingCallAlerting.name, CALL_FILE);
            uploadLogs({
                correlationId: this.correlationId,
                callId: this.callId,
            });
        }
    }
    handleIncomingCallConnect(event) {
        log.info(`handleIncomingCallConnect: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleIncomingCallConnect.name,
        });
        this.emit(CALL_EVENT_KEYS.CONNECT, this.correlationId);
        if (this.earlyMedia || this.mediaNegotiationCompleted) {
            this.mediaNegotiationCompleted = false;
            this.sendCallStateMachineEvt({ type: 'E_CALL_ESTABLISHED' });
        }
    }
    async handleOutgoingCallConnect(event) {
        log.info(`handleOutgoingCallConnect: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleOutgoingCallConnect.name,
        });
        if (!this.remoteRoapMessage) {
            log.warn('Offer not yet received from remote end... Exiting', {
                file: CALL_FILE,
                method: this.handleOutgoingCallConnect.name,
            });
            return;
        }
        try {
            this.mediaConnection.roapMessageReceived(this.remoteRoapMessage);
            const res = await this.patch(MobiusCallState.CONNECTED);
            log.log(`PATCH response: ${res.statusCode}`, {
                file: CALL_FILE,
                method: this.handleOutgoingCallConnect.name,
            });
        }
        catch (err) {
            log.warn('Failed to connect the call', {
                file: CALL_FILE,
                method: this.handleOutgoingCallConnect.name,
            });
            const errData = err;
            handleCallErrors((error) => {
                this.emit(CALL_EVENT_KEYS.CALL_ERROR, error);
                this.submitCallErrorMetric(error);
                this.sendCallStateMachineEvt({ type: 'E_UNKNOWN', data: errData });
            }, ERROR_LAYER.CALL_CONTROL, (interval) => undefined, this.getCorrelationId(), errData, this.handleOutgoingCallConnect.name, CALL_FILE);
            uploadLogs({
                correlationId: this.correlationId,
                callId: this.callId,
            });
        }
    }
    async handleIncomingCallDisconnect(event) {
        log.info(`handleIncomingCallDisconnect: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleIncomingCallDisconnect.name,
        });
        this.setDisconnectReason();
        try {
            const response = await this.delete();
            log.log(`handleOutgoingCallDisconnect: Response code: ${response.statusCode}`, {
                file: CALL_FILE,
                method: this.handleIncomingCallDisconnect.name,
            });
        }
        catch (e) {
            log.warn('Failed to delete the call', {
                file: CALL_FILE,
                method: this.handleIncomingCallDisconnect.name,
            });
        }
        this.deleteCb(this.correlationId);
        this.unregisterListeners();
        if (this.sessionTimer) {
            clearInterval(this.sessionTimer);
        }
        if (this.mediaConnection) {
            this.mediaConnection.close();
            log.info('Closing media channel', { file: CALL_FILE, method: 'handleIncomingCallDisconnect' });
        }
        this.sendMediaStateMachineEvt({ type: 'E_ROAP_TEARDOWN' });
        this.sendCallStateMachineEvt({ type: 'E_CALL_CLEARED' });
        this.emit(CALL_EVENT_KEYS.DISCONNECT, this.correlationId);
    }
    async handleOutgoingCallDisconnect(event) {
        this.setDisconnectReason();
        try {
            const response = await this.delete();
            log.log(`handleOutgoingCallDisconnect: Response code: ${response.statusCode}`, {
                file: CALL_FILE,
                method: this.handleOutgoingCallDisconnect.name,
            });
        }
        catch (e) {
            log.warn('Failed to delete the call', {
                file: CALL_FILE,
                method: this.handleOutgoingCallDisconnect.name,
            });
        }
        this.deleteCb(this.correlationId);
        this.unregisterListeners();
        if (this.sessionTimer) {
            clearInterval(this.sessionTimer);
        }
        if (this.mediaConnection) {
            this.mediaConnection.close();
            log.info('Closing media channel', { file: CALL_FILE, method: 'handleOutgoingCallDisconnect' });
        }
        this.sendMediaStateMachineEvt({ type: 'E_ROAP_TEARDOWN' });
        this.sendCallStateMachineEvt({ type: 'E_CALL_CLEARED' });
    }
    handleCallEstablished(event) {
        log.info(`handleCallEstablished: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleCallEstablished.name,
        });
        this.emit(CALL_EVENT_KEYS.ESTABLISHED, this.correlationId);
        this.earlyMedia = false;
        this.connected = true;
        if (this.sessionTimer) {
            log.log('Resetting session timer', {
                file: CALL_FILE,
                method: 'handleCallEstablished',
            });
            clearInterval(this.sessionTimer);
        }
        this.sessionTimer = setInterval(async () => {
            try {
                const res = await this.postStatus();
                log.info(`Session refresh successful`, {
                    file: CALL_FILE,
                    method: 'handleCallEstablished',
                });
            }
            catch (err) {
                const error = err;
                if (this.sessionTimer) {
                    clearInterval(this.sessionTimer);
                }
                handleCallErrors((callError) => {
                    this.emit(CALL_EVENT_KEYS.CALL_ERROR, callError);
                    this.submitCallErrorMetric(callError);
                }, ERROR_LAYER.CALL_CONTROL, (interval) => {
                    setTimeout(() => {
                        this.postStatus();
                        this.sendCallStateMachineEvt({ type: 'E_CALL_ESTABLISHED' });
                    }, interval * 1000);
                }, this.getCorrelationId(), error, this.handleCallEstablished.name, CALL_FILE);
                uploadLogs({
                    correlationId: this.correlationId,
                    callId: this.callId,
                });
            }
        }, DEFAULT_SESSION_TIMER);
    }
    async handleUnknownState(event) {
        log.info(`handleUnknownState: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: this.handleUnknownState.name,
        });
        const eventData = event.data;
        if (!eventData?.media) {
            log.warn('Call failed due to signalling issue', {
                file: CALL_FILE,
                method: this.handleUnknownState.name,
            });
        }
        try {
            this.setDisconnectReason();
            const response = await this.delete();
            log.log(`handleOutgoingCallDisconnect: Response code: ${response.statusCode}`, {
                file: CALL_FILE,
                method: this.handleUnknownState.name,
            });
        }
        catch (e) {
            log.warn('Failed to delete the call', {
                file: CALL_FILE,
                method: this.handleUnknownState.name,
            });
        }
        this.deleteCb(this.correlationId);
        if (this.sessionTimer) {
            clearInterval(this.sessionTimer);
        }
        if (this.mediaConnection) {
            this.mediaConnection.close();
            log.info('Closing media channel', {
                file: CALL_FILE,
                method: this.handleUnknownState.name,
            });
        }
        this.sendMediaStateMachineEvt({ type: 'E_ROAP_TEARDOWN' });
        this.sendCallStateMachineEvt({ type: 'E_CALL_CLEARED' });
    }
    getEmitterCallback(errData) {
        return (error) => {
            switch (this.callStateMachine.state.value) {
                case 'S_CALL_HOLD':
                    this.emit(CALL_EVENT_KEYS.HOLD_ERROR, error);
                    if (this.supplementaryServicesTimer) {
                        clearTimeout(this.supplementaryServicesTimer);
                        this.supplementaryServicesTimer = undefined;
                    }
                    this.submitCallErrorMetric(error);
                    this.sendCallStateMachineEvt({ type: 'E_CALL_ESTABLISHED', data: errData });
                    return;
                case 'S_CALL_RESUME':
                    this.emit(CALL_EVENT_KEYS.RESUME_ERROR, error);
                    this.submitCallErrorMetric(error);
                    this.sendCallStateMachineEvt({ type: 'E_CALL_ESTABLISHED', data: errData });
                    return;
                default:
                    this.emit(CALL_EVENT_KEYS.CALL_ERROR, error);
                    this.submitCallErrorMetric(error);
                    if (!this.connected) {
                        this.sendMediaStateMachineEvt({ type: 'E_ROAP_ERROR', data: errData });
                    }
            }
        };
    }
    async handleRoapEstablished(context, event) {
        log.info(`handleRoapEstablished: ${this.getCorrelationId()}  `, {
            file: CALL_FILE,
            method: 'handleRoapEstablished',
        });
        const { received, message } = event.data;
        this.receivedRoapOKSeq = message.seq;
        if (!received) {
            log.info('Sending Media Ok to the remote End', {
                file: CALL_FILE,
                method: 'handleRoapEstablished',
            });
            try {
                if (this.callStateMachine.state.value === 'S_RECV_CALL_PROGRESS' ||
                    this.callStateMachine.state.value === 'S_SEND_CALL_SETUP') {
                    log.info('Media negotiation completed before call connect. Setting media negotiation completed flag.', {
                        file: CALL_FILE,
                        method: 'handleRoapEstablished',
                    });
                    this.mediaNegotiationCompleted = true;
                }
                message.seq = this.seq;
                const res = await this.postMedia(message);
                log.log(`handleRoapEstablished: Response code: ${res.statusCode}`, {
                    file: CALL_FILE,
                    method: 'handleRoapEstablished',
                });
                if (!this.earlyMedia && !this.mediaNegotiationCompleted) {
                    this.sendCallStateMachineEvt({ type: 'E_CALL_ESTABLISHED' });
                }
            }
            catch (err) {
                log.warn('Failed to process MediaOk request', {
                    file: CALL_FILE,
                    method: 'handleRoapEstablished',
                });
                const errData = err;
                handleCallErrors(this.getEmitterCallback(errData), ERROR_LAYER.MEDIA, (interval) => {
                    if (this.connected) {
                        setTimeout(() => {
                            this.sendMediaStateMachineEvt({ type: 'E_ROAP_OK', data: event.data });
                        }, interval * 1000);
                    }
                }, this.getCorrelationId(), errData, this.handleRoapEstablished.name, CALL_FILE);
                uploadLogs({
                    correlationId: this.correlationId,
                    callId: this.callId,
                });
            }
        }
        else {
            log.info('Notifying internal-media-core about ROAP OK message', {
                file: CALL_FILE,
                method: 'handleRoapEstablished',
            });
            message.seq = this.seq;
            if (this.mediaConnection) {
                this.mediaConnection.roapMessageReceived(message);
            }
            if (!this.earlyMedia) {
                this.sendCallStateMachineEvt({ type: 'E_CALL_ESTABLISHED' });
            }
            if (this.remoteRoapMessage && this.remoteRoapMessage.seq > this.seq) {
                if (this.remoteRoapMessage.messageType === 'OFFER_REQUEST') {
                    this.sendMediaStateMachineEvt({
                        type: 'E_RECV_ROAP_OFFER_REQUEST',
                        data: this.remoteRoapMessage,
                    });
                }
                else if (this.remoteRoapMessage.messageType === 'OFFER') {
                    this.sendMediaStateMachineEvt({ type: 'E_RECV_ROAP_OFFER', data: this.remoteRoapMessage });
                }
            }
        }
    }
    async handleRoapError(context, event) {
        log.info(`handleRoapError: ${this.getCorrelationId()}`, {
            file: CALL_FILE,
            method: this.handleRoapError.name,
        });
        const message = event.data;
        if (message) {
            try {
                const res = await this.postMedia(message);
                log.info(`Response code: ${res.statusCode}`, {
                    file: CALL_FILE,
                    method: this.handleRoapError.name,
                });
            }
            catch (err) {
                log.warn('Failed to communicate ROAP error to Webex Calling', {
                    file: CALL_FILE,
                    method: this.handleRoapError.name,
                });
                const errData = err;
                handleCallErrors((error) => {
                    this.emit(CALL_EVENT_KEYS.CALL_ERROR, error);
                    this.submitCallErrorMetric(error);
                }, ERROR_LAYER.MEDIA, (interval) => undefined, this.getCorrelationId(), errData, this.handleRoapError.name, CALL_FILE);
                uploadLogs({
                    correlationId: this.correlationId,
                    callId: this.callId,
                });
            }
        }
        if (!this.connected) {
            log.warn('Call failed due to media issue', {
                file: CALL_FILE,
                method: 'handleRoapError',
            });
            this.sendCallStateMachineEvt({ type: 'E_UNKNOWN', data: { media: true } });
        }
    }
    async handleOutgoingRoapOffer(context, event) {
        log.info(`handleOutgoingRoapOffer: ${this.getCorrelationId()}`, {
            file: CALL_FILE,
            method: this.handleOutgoingRoapOffer.name,
        });
        const message = event.data;
        if (!message?.sdp) {
            log.info('Initializing Offer...', {
                file: CALL_FILE,
                method: this.handleOutgoingRoapOffer.name,
            });
            this.mediaConnection.initiateOffer();
            return;
        }
        try {
            const res = await this.postMedia(message);
            log.log(`handleOutgoingRoapOffer: Response code: ${res.statusCode}`, {
                file: CALL_FILE,
                method: this.handleOutgoingRoapOffer.name,
            });
        }
        catch (err) {
            log.warn('Failed to process MediaOk request', {
                file: CALL_FILE,
                method: this.handleOutgoingRoapOffer.name,
            });
            const errData = err;
            handleCallErrors(this.getEmitterCallback(errData), ERROR_LAYER.MEDIA, (interval) => {
                if (this.connected) {
                    setTimeout(() => {
                        this.sendMediaStateMachineEvt({ type: 'E_SEND_ROAP_OFFER', data: event.data });
                    }, interval * 1000);
                }
            }, this.getCorrelationId(), errData, this.handleOutgoingRoapOffer.name, CALL_FILE);
            uploadLogs({
                correlationId: this.correlationId,
                callId: this.callId,
            });
        }
    }
    async handleOutgoingRoapAnswer(context, event) {
        log.info(`handleOutgoingRoapAnswer: ${this.getCorrelationId()}`, {
            file: CALL_FILE,
            method: this.handleOutgoingRoapAnswer.name,
        });
        const message = event.data;
        try {
            message.seq = this.seq;
            const res = await this.postMedia(message);
            log.log(`handleOutgoingRoapAnswer: Response code: ${res.statusCode}`, {
                file: CALL_FILE,
                method: this.handleOutgoingRoapAnswer.name,
            });
        }
        catch (err) {
            log.warn('Failed to send MediaAnswer request', {
                file: CALL_FILE,
                method: this.handleOutgoingRoapAnswer.name,
            });
            const errData = err;
            handleCallErrors(this.getEmitterCallback(errData), ERROR_LAYER.MEDIA, (interval) => {
                if (this.connected) {
                    setTimeout(() => {
                        this.sendMediaStateMachineEvt({ type: 'E_SEND_ROAP_ANSWER', data: event.data });
                    }, interval * 1000);
                }
            }, this.getCorrelationId(), errData, this.handleOutgoingRoapAnswer.name, CALL_FILE);
            uploadLogs({
                correlationId: this.correlationId,
                callId: this.callId,
            });
        }
    }
    handleIncomingRoapOffer(context, event) {
        log.info(`handleIncomingRoapOffer: ${this.getCorrelationId()}`, {
            file: CALL_FILE,
            method: this.handleIncomingRoapOffer.name,
        });
        const message = event.data;
        this.remoteRoapMessage = message;
        if (!this.mediaConnection) {
            log.info('Media connection is not up, buffer the remote offer for later handling', {
                file: CALL_FILE,
                method: this.handleIncomingRoapOffer.name,
            });
            this.seq = message.seq;
            log.info(`Setting Sequence No: ${this.seq}`, {
                file: CALL_FILE,
                method: this.handleIncomingRoapOffer.name,
            });
        }
        else if (this.receivedRoapOKSeq === message.seq - 2) {
            log.info('Waiting for Roap OK, buffer the remote offer for later handling', {
                file: CALL_FILE,
                method: this.handleIncomingRoapOffer.name,
            });
            this.remoteRoapMessage = message;
        }
        else {
            log.info('Handling new offer...', {
                file: CALL_FILE,
                method: this.handleIncomingRoapOffer.name,
            });
            this.seq = message.seq;
            if (this.mediaConnection) {
                this.mediaConnection.roapMessageReceived(message);
            }
        }
    }
    handleIncomingRoapAnswer(context, event) {
        log.info(`handleIncomingRoapAnswer: ${this.getCorrelationId()}`, {
            file: CALL_FILE,
            method: this.handleIncomingRoapAnswer.name,
        });
        const message = event.data;
        this.remoteRoapMessage = message;
        message.seq = this.seq;
        if (this.mediaConnection) {
            this.mediaConnection.roapMessageReceived(message);
        }
    }
    forceSendStatsReport = async ({ callFrom }) => {
        const loggerContext = {
            file: CALL_FILE,
            method: this.forceSendStatsReport.name,
        };
        try {
            await this.mediaConnection.forceRtcMetricsSend();
            log.info(`Successfully uploaded available webrtc telemetry statistics`, loggerContext);
            log.info(`callFrom: ${callFrom}`, loggerContext);
        }
        catch (error) {
            const errorInfo = error;
            const errorStatus = serviceErrorCodeHandler(errorInfo, loggerContext);
            const errorLog = new Error(`Failed to upload webrtc telemetry statistics. ${errorStatus}`);
            log.error(errorLog, loggerContext);
        }
    };
    initMediaConnection(localAudioTrack, debugId) {
        const mediaConnection = new RoapMediaConnection({
            skipInactiveTransceivers: true,
            iceServers: [],
            iceCandidatesTimeout: ICE_CANDIDATES_TIMEOUT,
            sdpMunging: {
                convertPort9to0: true,
                addContentSlides: false,
                copyClineToSessionLevel: true,
            },
        }, {
            localTracks: { audio: localAudioTrack },
            direction: {
                audio: 'sendrecv',
                video: 'inactive',
                screenShareVideo: 'inactive',
            },
        }, debugId || `WebexCallSDK-${this.correlationId}`, (data) => this.rtcMetrics.addMetrics(data), () => this.rtcMetrics.closeMetrics(), () => this.rtcMetrics.sendMetricsInQueue());
        this.mediaConnection = mediaConnection;
    }
    getDirection = () => this.direction;
    getCallId = () => this.callId;
    getCorrelationId = () => this.correlationId;
    sendCallStateMachineEvt(event) {
        this.callStateMachine.send(event);
    }
    sendMediaStateMachineEvt(event) {
        this.mediaStateMachine.send(event);
    }
    setCallId = (callId) => {
        this.callId = callId;
        this.rtcMetrics.updateCallId(callId);
        log.info(`Setting callId : ${this.callId} for correlationId: ${this.correlationId}`, {
            file: CALL_FILE,
            method: this.setCallId.name,
        });
    };
    setDisconnectReason() {
        if (this.mediaInactivity) {
            this.disconnectReason.code = DisconnectCode.MEDIA_INACTIVITY;
            this.disconnectReason.cause = DisconnectCause.MEDIA_INACTIVITY;
        }
        else if (this.connected || this.direction === CallDirection.OUTBOUND) {
            this.disconnectReason.code = DisconnectCode.NORMAL;
            this.disconnectReason.cause = DisconnectCause.NORMAL;
        }
        else {
            this.disconnectReason.code = DisconnectCode.BUSY;
            this.disconnectReason.cause = DisconnectCause.BUSY;
        }
    }
    getDisconnectReason = () => {
        return this.disconnectReason;
    };
    async answer(localAudioStream) {
        this.localAudioStream = localAudioStream;
        const localAudioTrack = localAudioStream.outputStream.getAudioTracks()[0];
        if (!localAudioTrack) {
            log.warn(`Did not find a local track while answering the call ${this.getCorrelationId()}`, {
                file: CALL_FILE,
                method: 'answer',
            });
            this.mediaInactivity = true;
            this.sendCallStateMachineEvt({ type: 'E_SEND_CALL_DISCONNECT' });
            return;
        }
        localAudioTrack.enabled = true;
        if (!this.mediaConnection) {
            this.initMediaConnection(localAudioTrack);
            this.mediaRoapEventsListener();
            this.mediaTrackListener();
            this.registerListeners(localAudioStream);
        }
        if (this.callStateMachine.state.value === 'S_SEND_CALL_PROGRESS') {
            this.sendCallStateMachineEvt({ type: 'E_SEND_CALL_CONNECT' });
        }
        else {
            log.warn(`Call cannot be answered because the state is : ${this.callStateMachine.state.value}`, { file: CALL_FILE, method: 'answer' });
        }
    }
    async dial(localAudioStream) {
        this.localAudioStream = localAudioStream;
        const localAudioTrack = localAudioStream.outputStream.getAudioTracks()[0];
        if (!localAudioTrack) {
            log.warn(`Did not find a local track while dialing the call ${this.getCorrelationId()}`, {
                file: CALL_FILE,
                method: 'dial',
            });
            this.deleteCb(this.getCorrelationId());
            this.emit(CALL_EVENT_KEYS.DISCONNECT, this.getCorrelationId());
            return;
        }
        localAudioTrack.enabled = true;
        if (!this.mediaConnection) {
            this.initMediaConnection(localAudioTrack);
            this.mediaRoapEventsListener();
            this.mediaTrackListener();
            this.registerListeners(localAudioStream);
        }
        if (this.mediaStateMachine.state.value === 'S_ROAP_IDLE') {
            this.sendMediaStateMachineEvt({ type: 'E_SEND_ROAP_OFFER' });
        }
        else {
            log.warn(`Call cannot be dialed because the state is already : ${this.mediaStateMachine.state.value}`, { file: CALL_FILE, method: 'dial' });
        }
    }
    post = async (roapMessage) => {
        const basePayload = {
            device: {
                deviceId: this.deviceId,
                correlationId: this.correlationId,
            },
            localMedia: {
                roap: roapMessage,
                mediaId: uuid(),
            },
        };
        return this.webex.request({
            uri: `${this.mobiusUrl}${DEVICES_ENDPOINT_RESOURCE}/${this.deviceId}/${CALL_ENDPOINT_RESOURCE}`,
            method: HTTP_METHODS.POST,
            service: ALLOWED_SERVICES.MOBIUS,
            headers: {
                [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                [SPARK_USER_AGENT]: CALLING_USER_AGENT,
            },
            body: this.destination
                ? {
                    ...basePayload,
                    callee: {
                        type: this.destination.type,
                        address: this.destination.address,
                    },
                }
                : basePayload,
        });
    };
    async patch(state) {
        log.info(`Send a PATCH for ${state} to Webex Calling`, {
            file: CALL_FILE,
            method: this.patch.name,
        });
        return this.webex.request({
            uri: `${this.mobiusUrl}${DEVICES_ENDPOINT_RESOURCE}/${this.deviceId}/${CALLS_ENDPOINT_RESOURCE}/${this.callId}`,
            method: HTTP_METHODS.PATCH,
            service: ALLOWED_SERVICES.MOBIUS,
            headers: {
                [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                [SPARK_USER_AGENT]: CALLING_USER_AGENT,
            },
            body: {
                device: {
                    deviceId: this.deviceId,
                    correlationId: this.correlationId,
                },
                callId: this.callId,
                callState: state,
                inbandMedia: false,
            },
        });
    }
    async postSSRequest(context, type) {
        const request = {
            uri: `${this.mobiusUrl}${SERVICES_ENDPOINT}`,
            method: HTTP_METHODS.POST,
            service: ALLOWED_SERVICES.MOBIUS,
            headers: {
                [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                [SPARK_USER_AGENT]: CALLING_USER_AGENT,
            },
            body: {
                device: {
                    deviceId: this.deviceId,
                    correlationId: this.correlationId,
                },
                callId: this.callId,
            },
        };
        switch (type) {
            case SUPPLEMENTARY_SERVICES.HOLD: {
                request.uri = `${request.uri}/${CALL_HOLD_SERVICE}/${HOLD_ENDPOINT}`;
                break;
            }
            case SUPPLEMENTARY_SERVICES.RESUME: {
                request.uri = `${request.uri}/${CALL_HOLD_SERVICE}/${RESUME_ENDPOINT}`;
                break;
            }
            case SUPPLEMENTARY_SERVICES.TRANSFER: {
                request.uri = `${request.uri}/${CALL_TRANSFER_SERVICE}/${TRANSFER_ENDPOINT}`;
                const transferContext = context;
                if (transferContext.destination) {
                    Object.assign(request.body, { blindTransferContext: transferContext });
                    Object.assign(request.body, { transferType: TransferType.BLIND });
                }
                else if (transferContext.transferToCallId) {
                    Object.assign(request.body, { consultTransferContext: transferContext });
                    Object.assign(request.body, { transferType: TransferType.CONSULT });
                }
                break;
            }
            default: {
                log.warn(`Unknown type for PUT request: ${type}`, {
                    file: CALL_FILE,
                    method: this.postSSRequest.name,
                });
            }
        }
        return this.webex.request(request);
    }
    async postStatus() {
        return this.webex.request({
            uri: `${this.mobiusUrl}${DEVICES_ENDPOINT_RESOURCE}/${this.deviceId}/${CALLS_ENDPOINT_RESOURCE}/${this.callId}/${CALL_STATUS_RESOURCE}`,
            method: HTTP_METHODS.POST,
            service: ALLOWED_SERVICES.MOBIUS,
            headers: {
                [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                [SPARK_USER_AGENT]: CALLING_USER_AGENT,
            },
            body: {
                device: {
                    deviceId: this.deviceId,
                    correlationId: this.correlationId,
                },
                callId: this.callId,
            },
        });
    }
    async completeTransfer(transferType, transferCallId, transferTarget) {
        if (transferType === TransferType.BLIND && transferTarget) {
            log.info(`Initiating Blind transfer with : ${transferTarget}`, {
                file: CALL_FILE,
                method: this.completeTransfer.name,
            });
            const context = {
                transferorCallId: this.getCallId(),
                destination: transferTarget,
            };
            try {
                await this.postSSRequest(context, SUPPLEMENTARY_SERVICES.TRANSFER);
                this.metricManager.submitCallMetric(METRIC_EVENT.CALL, TRANSFER_ACTION.BLIND, METRIC_TYPE.BEHAVIORAL, this.getCallId(), this.getCorrelationId(), undefined);
            }
            catch (e) {
                log.warn(`Blind Transfer failed for correlationId ${this.getCorrelationId()}`, {
                    file: CALL_FILE,
                    method: this.completeTransfer.name,
                });
                const errData = e;
                handleCallErrors((error) => {
                    this.emit(CALL_EVENT_KEYS.TRANSFER_ERROR, error);
                    this.submitCallErrorMetric(error, TRANSFER_ACTION.BLIND);
                }, ERROR_LAYER.CALL_CONTROL, (interval) => undefined, this.getCorrelationId(), errData, this.completeTransfer.name, CALL_FILE);
                uploadLogs({
                    correlationId: this.correlationId,
                    callId: this.callId,
                });
            }
        }
        else if (transferType === TransferType.CONSULT && transferCallId) {
            log.info(`Initiating Consult transfer between : ${this.callId} and ${transferCallId}`, {
                file: CALL_FILE,
                method: this.completeTransfer.name,
            });
            const context = {
                transferorCallId: this.getCallId(),
                transferToCallId: transferCallId,
            };
            try {
                await this.postSSRequest(context, SUPPLEMENTARY_SERVICES.TRANSFER);
                this.metricManager.submitCallMetric(METRIC_EVENT.CALL, TRANSFER_ACTION.CONSULT, METRIC_TYPE.BEHAVIORAL, this.getCallId(), this.getCorrelationId(), undefined);
            }
            catch (e) {
                log.warn(`Consult Transfer failed for correlationId ${this.getCorrelationId()}`, {
                    file: CALL_FILE,
                    method: this.completeTransfer.name,
                });
                const errData = e;
                handleCallErrors((error) => {
                    this.emit(CALL_EVENT_KEYS.TRANSFER_ERROR, error);
                    this.submitCallErrorMetric(error, TRANSFER_ACTION.CONSULT);
                }, ERROR_LAYER.CALL_CONTROL, (interval) => undefined, this.getCorrelationId(), errData, this.completeTransfer.name, CALL_FILE);
                uploadLogs({
                    correlationId: this.correlationId,
                    callId: this.callId,
                });
            }
        }
        else {
            log.warn(`Invalid information received, transfer failed for correlationId: ${this.getCorrelationId()}`, {
                file: CALL_FILE,
                method: this.completeTransfer.name,
            });
        }
    }
    async getCallStats() {
        let stats;
        try {
            stats = await this.mediaConnection.getStats();
        }
        catch (err) {
            log.warn('Stats collection failed, using dummy stats', {
                file: CALL_FILE,
                method: this.getCallStats.name,
            });
        }
        return parseMediaQualityStatistics(stats);
    }
    async postMedia(roapMessage) {
        log.log('Posting message to Webex Calling', {
            file: CALL_FILE,
            method: this.postMedia.name,
        });
        return this.webex.request({
            uri: `${this.mobiusUrl}${DEVICES_ENDPOINT_RESOURCE}/${this.deviceId}/${CALLS_ENDPOINT_RESOURCE}/${this.callId}/${MEDIA_ENDPOINT_RESOURCE}`,
            method: HTTP_METHODS.POST,
            service: ALLOWED_SERVICES.MOBIUS,
            headers: {
                [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                [SPARK_USER_AGENT]: CALLING_USER_AGENT,
            },
            body: {
                device: {
                    deviceId: this.deviceId,
                    correlationId: this.correlationId,
                },
                callId: this.callId,
                localMedia: {
                    roap: roapMessage,
                    mediaId: uuid(),
                },
            },
        });
    }
    mediaRoapEventsListener() {
        this.mediaConnection.on(MediaConnectionEventNames.ROAP_MESSAGE_TO_SEND, async (event) => {
            log.info(`ROAP message to send (rcv from MEDIA-SDK) :
          \n type:  ${event.roapMessage?.messageType}, seq: ${event.roapMessage.seq} , version: ${event.roapMessage.version}`, {});
            log.info(`SDP message to send : \n ${event.roapMessage?.sdp}`, {
                file: CALL_FILE,
                method: this.mediaRoapEventsListener.name,
            });
            switch (event.roapMessage.messageType) {
                case RoapScenario.OK: {
                    const mediaOk = {
                        received: false,
                        message: event.roapMessage,
                    };
                    this.sendMediaStateMachineEvt({ type: 'E_ROAP_OK', data: mediaOk });
                    break;
                }
                case RoapScenario.OFFER: {
                    log.info(`before modifying sdp: ${event.roapMessage.sdp}`, {
                        file: CALL_FILE,
                        method: this.mediaRoapEventsListener.name,
                    });
                    event.roapMessage.sdp = modifySdpForIPv4(event.roapMessage.sdp);
                    const sdpVideoPortZero = event.roapMessage.sdp.replace(/^m=(video) (?:\d+) /gim, 'm=$1 0 ');
                    log.info(`after modification sdp: ${sdpVideoPortZero}`, {
                        file: CALL_FILE,
                        method: this.mediaRoapEventsListener.name,
                    });
                    event.roapMessage.sdp = sdpVideoPortZero;
                    this.localRoapMessage = event.roapMessage;
                    this.sendCallStateMachineEvt({ type: 'E_SEND_CALL_SETUP', data: event.roapMessage });
                    break;
                }
                case RoapScenario.ANSWER:
                    event.roapMessage.sdp = modifySdpForIPv4(event.roapMessage.sdp);
                    this.localRoapMessage = event.roapMessage;
                    this.sendMediaStateMachineEvt({ type: 'E_SEND_ROAP_ANSWER', data: event.roapMessage });
                    break;
                case RoapScenario.ERROR:
                    this.sendMediaStateMachineEvt({ type: 'E_ROAP_ERROR', data: event.roapMessage });
                    break;
                case RoapScenario.OFFER_RESPONSE:
                    event.roapMessage.sdp = modifySdpForIPv4(event.roapMessage.sdp);
                    this.localRoapMessage = event.roapMessage;
                    this.sendMediaStateMachineEvt({ type: 'E_SEND_ROAP_OFFER', data: event.roapMessage });
                    break;
                default:
            }
        });
    }
    mediaTrackListener() {
        this.mediaConnection.on(MediaConnectionEventNames.REMOTE_TRACK_ADDED, (e) => {
            if (e.type === MEDIA_CONNECTION_EVENT_KEYS.MEDIA_TYPE_AUDIO) {
                this.emit(CALL_EVENT_KEYS.REMOTE_MEDIA, e.track);
            }
        });
    }
    onEffectEnabled = () => {
        this.metricManager.submitBNRMetric(METRIC_EVENT.BNR_ENABLED, METRIC_TYPE.BEHAVIORAL, this.callId, this.correlationId);
    };
    onEffectDisabled = () => {
        this.metricManager.submitBNRMetric(METRIC_EVENT.BNR_DISABLED, METRIC_TYPE.BEHAVIORAL, this.callId, this.correlationId);
    };
    updateTrack = (audioTrack) => {
        this.mediaConnection.updateLocalTracks({ audio: audioTrack });
    };
    registerEffectListener = (addedEffect) => {
        if (this.localAudioStream) {
            const effect = this.localAudioStream.getEffectByKind(NOISE_REDUCTION_EFFECT);
            if (effect === addedEffect) {
                effect.on(EffectEvent.Enabled, this.onEffectEnabled);
                effect.on(EffectEvent.Disabled, this.onEffectDisabled);
            }
        }
    };
    unregisterListeners() {
        if (this.localAudioStream) {
            const effect = this.localAudioStream.getEffectByKind(NOISE_REDUCTION_EFFECT);
            if (effect) {
                effect.off(EffectEvent.Enabled, this.onEffectEnabled);
                effect.off(EffectEvent.Disabled, this.onEffectDisabled);
            }
            this.localAudioStream.off(LocalStreamEventNames.EffectAdded, this.registerEffectListener);
            this.localAudioStream.off(LocalStreamEventNames.OutputTrackChange, this.updateTrack);
        }
    }
    registerListeners(localAudioStream) {
        localAudioStream.on(LocalStreamEventNames.OutputTrackChange, this.updateTrack);
        localAudioStream.on(LocalStreamEventNames.EffectAdded, this.registerEffectListener);
        const effect = localAudioStream.getEffectByKind(NOISE_REDUCTION_EFFECT);
        if (effect) {
            effect.on(EffectEvent.Enabled, this.onEffectEnabled);
            effect.on(EffectEvent.Disabled, this.onEffectDisabled);
            if (effect.isEnabled) {
                this.onEffectEnabled();
            }
        }
    }
    async delete() {
        const disconnectMetrics = await this.getCallStats();
        return this.webex.request({
            uri: `${this.mobiusUrl}${DEVICES_ENDPOINT_RESOURCE}/${this.deviceId}/${CALLS_ENDPOINT_RESOURCE}/${this.callId}`,
            method: HTTP_METHODS.DELETE,
            service: ALLOWED_SERVICES.MOBIUS,
            headers: {
                [CISCO_DEVICE_URL]: this.webex.internal.device.url,
                [SPARK_USER_AGENT]: CALLING_USER_AGENT,
            },
            body: {
                device: {
                    deviceId: this.deviceId,
                    correlationId: this.correlationId,
                },
                callId: this.callId,
                metrics: disconnectMetrics,
                causecode: this.disconnectReason.code,
                cause: this.disconnectReason.cause,
            },
        });
    }
    submitCallErrorMetric(error, transferMetricAction) {
        if (error.getCallError().errorLayer === ERROR_LAYER.CALL_CONTROL) {
            this.metricManager.submitCallMetric(METRIC_EVENT.CALL_ERROR, transferMetricAction || this.callStateMachine.state.value.toString(), METRIC_TYPE.BEHAVIORAL, this.callId, this.correlationId, error);
        }
        else {
            this.metricManager.submitMediaMetric(METRIC_EVENT.MEDIA_ERROR, this.mediaStateMachine.state.value.toString(), METRIC_TYPE.BEHAVIORAL, this.callId, this.correlationId, this.localRoapMessage.sdp, this.remoteRoapMessage?.sdp, error);
        }
    }
    handleMidCallEvent(event) {
        const { eventType, eventData } = event;
        switch (eventType) {
            case MidCallEventType.CALL_INFO: {
                log.log(`Received Midcall CallInfo Event for correlationId : ${this.correlationId}`, {
                    file: CALL_FILE,
                    method: 'handleMidCallEvent',
                });
                const callerData = eventData;
                this.startCallerIdResolution(callerData.callerId);
                break;
            }
            case MidCallEventType.CALL_STATE: {
                log.log(`Received Midcall call event for correlationId : ${this.correlationId}`, {
                    file: CALL_FILE,
                    method: 'handleMidCallEvent',
                });
                const data = eventData;
                switch (data.callState) {
                    case MOBIUS_MIDCALL_STATE.HELD: {
                        log.log(`Call is successfully held : ${this.correlationId}`, {
                            file: CALL_FILE,
                            method: 'handleMidCallEvent',
                        });
                        this.emit(CALL_EVENT_KEYS.HELD, this.correlationId);
                        this.held = true;
                        if (this.supplementaryServicesTimer) {
                            clearTimeout(this.supplementaryServicesTimer);
                            this.supplementaryServicesTimer = undefined;
                        }
                        break;
                    }
                    case MOBIUS_MIDCALL_STATE.CONNECTED: {
                        log.log(`Call is successfully resumed : ${this.correlationId}`, {
                            file: CALL_FILE,
                            method: 'handleMidCallEvent',
                        });
                        this.emit(CALL_EVENT_KEYS.RESUMED, this.correlationId);
                        this.held = false;
                        if (this.supplementaryServicesTimer) {
                            clearTimeout(this.supplementaryServicesTimer);
                            this.supplementaryServicesTimer = undefined;
                        }
                        break;
                    }
                    default: {
                        log.warn(`Unknown Supplementary service state: ${data.callState} for correlationId : ${this.correlationId}`, {
                            file: CALL_FILE,
                            method: 'handleMidCallEvent',
                        });
                    }
                }
                break;
            }
            default: {
                log.warn(`Unknown Midcall type: ${eventType} for correlationId : ${this.correlationId}`, {
                    file: CALL_FILE,
                    method: 'handleMidCallEvent',
                });
            }
        }
    }
    getCallerInfo = () => this.callerInfo;
    end = () => {
        this.sendCallStateMachineEvt({ type: 'E_SEND_CALL_DISCONNECT' });
    };
    doHoldResume = () => {
        if (this.held) {
            this.sendCallStateMachineEvt({ type: 'E_CALL_RESUME' });
        }
        else {
            this.sendCallStateMachineEvt({ type: 'E_CALL_HOLD' });
        }
    };
    startCallerIdResolution(callerInfo) {
        this.callerInfo = this.callerId.fetchCallerDetails(callerInfo);
    }
    sendDigit(tone) {
        try {
            log.info(`Sending digit : ${tone}`, {
                file: CALL_FILE,
                method: 'sendDigit',
            });
            this.mediaConnection.insertDTMF(tone);
        }
        catch (e) {
            log.warn(`Unable to send digit on call: ${e.message}`, {
                file: CALL_FILE,
                method: 'sendDigit',
            });
        }
    }
    mute = (localAudioStream, muteType) => {
        if (!localAudioStream) {
            log.warn(`Did not find a local stream while muting the call ${this.getCorrelationId()}.`, {
                file: CALL_FILE,
                method: 'mute',
            });
            return;
        }
        if (muteType === MUTE_TYPE.SYSTEM) {
            if (!localAudioStream.userMuted) {
                this.muted = localAudioStream.systemMuted;
            }
            else {
                log.info(`Call is muted by the user already - ${this.getCorrelationId()}.`, {
                    file: CALL_FILE,
                    method: 'mute',
                });
            }
        }
        else if (!localAudioStream.systemMuted) {
            localAudioStream.setUserMuted(!this.muted);
            this.muted = !this.muted;
        }
        else {
            log.info(`Call is muted on the system - ${this.getCorrelationId()}.`, {
                file: CALL_FILE,
                method: 'mute',
            });
        }
    };
    updateMedia = (newAudioStream) => {
        const localAudioTrack = newAudioStream.outputStream.getAudioTracks()[0];
        if (!localAudioTrack) {
            log.warn(`Did not find a local track while updating media for call ${this.getCorrelationId()}. Will not update media`, {
                file: CALL_FILE,
                method: 'updateMedia',
            });
            return;
        }
        try {
            this.mediaConnection.updateLocalTracks({
                audio: localAudioTrack,
            });
            this.unregisterListeners();
            this.registerListeners(newAudioStream);
            this.localAudioStream = newAudioStream;
        }
        catch (e) {
            log.warn(`Unable to update media on call ${this.getCorrelationId()}. Error: ${e.message}`, {
                file: CALL_FILE,
                method: 'updateMedia',
            });
        }
    };
    setBroadworksCorrelationInfo(broadworksCorrelationInfo) {
        this.broadworksCorrelationInfo = broadworksCorrelationInfo;
    }
    getBroadworksCorrelationInfo() {
        return this.broadworksCorrelationInfo;
    }
    getCallRtpStats() {
        return this.getCallStats();
    }
    async handleTimeout() {
        log.warn(`Call timed out`, {
            file: CALL_FILE,
            method: 'handleTimeout',
        });
        this.deleteCb(this.getCorrelationId());
        this.emit(CALL_EVENT_KEYS.DISCONNECT, this.getCorrelationId());
        const response = await this.delete();
        log.log(`handleTimeout: Response code: ${response.statusCode}`, {
            file: CALL_FILE,
            method: this.handleTimeout.name,
        });
    }
}
export const createCall = (activeUrl, webex, dir, deviceId, lineId, deleteCb, indicator, dest) => new Call(activeUrl, webex, dir, deviceId, lineId, deleteCb, indicator, dest);
