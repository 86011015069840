import { REPO_NAME } from '../CallingClient/constants';
import { LOGGING_LEVEL, LOGGER, LOG_PREFIX } from './types';
let currentLogLevel = LOGGING_LEVEL.error;
let webexLogger = console;
const writeToLogger = (message, level) => {
    switch (level) {
        case LOGGER.INFO:
            webexLogger.info(message);
            break;
        case LOGGER.LOG: {
            webexLogger.log(message);
            break;
        }
        case LOGGER.WARN: {
            webexLogger.warn(message);
            break;
        }
        case LOGGER.ERROR: {
            webexLogger.error(message);
            break;
        }
        case LOGGER.TRACE: {
            webexLogger.trace(message);
            break;
        }
        default: {
        }
    }
};
const format = (context, level) => {
    const timestamp = new Date().toUTCString();
    return `${REPO_NAME}: ${timestamp}: ${level}: ${LOG_PREFIX.FILE}:${context.file} - ${LOG_PREFIX.METHOD}:${context.method}`;
};
const setLogger = (level, module) => {
    switch (level) {
        case LOGGER.WARN: {
            currentLogLevel = LOGGING_LEVEL.warn;
            break;
        }
        case LOGGER.LOG: {
            currentLogLevel = LOGGING_LEVEL.log;
            break;
        }
        case LOGGER.INFO: {
            currentLogLevel = LOGGING_LEVEL.info;
            break;
        }
        case LOGGER.TRACE: {
            currentLogLevel = LOGGING_LEVEL.trace;
            break;
        }
        default: {
            currentLogLevel = LOGGING_LEVEL.error;
        }
    }
    const message = `Logger initialized for module: ${module} with level: ${currentLogLevel}`;
    writeToLogger(`${format({ file: 'logger.ts', method: 'setLogger' }, '')}  - ${LOG_PREFIX.MESSAGE}:${message}`, LOGGER.INFO);
};
const getLogLevel = () => {
    let level;
    switch (currentLogLevel) {
        case LOGGING_LEVEL.warn: {
            level = LOGGER.WARN;
            break;
        }
        case LOGGING_LEVEL.log: {
            level = LOGGER.LOG;
            break;
        }
        case LOGGING_LEVEL.info: {
            level = LOGGER.INFO;
            break;
        }
        case LOGGING_LEVEL.trace: {
            level = LOGGER.TRACE;
            break;
        }
        default: {
            level = LOGGER.ERROR;
        }
    }
    return level;
};
const logMessage = (message, context) => {
    if (currentLogLevel >= LOGGING_LEVEL.log) {
        writeToLogger(`${format(context, '[LOG]')} - ${LOG_PREFIX.MESSAGE}:${message}`, LOGGER.LOG);
    }
};
const logInfo = (message, context) => {
    if (currentLogLevel >= LOGGING_LEVEL.info) {
        writeToLogger(`${format(context, '[INFO]')} - ${LOG_PREFIX.MESSAGE}:${message}`, LOGGER.INFO);
    }
};
const logWarn = (message, context) => {
    if (currentLogLevel >= LOGGING_LEVEL.warn) {
        writeToLogger(`${format(context, '[WARN]')} - ${LOG_PREFIX.MESSAGE}:${message}`, LOGGER.WARN);
    }
};
const logTrace = (message, context) => {
    if (currentLogLevel >= LOGGING_LEVEL.trace) {
        writeToLogger(`${format(context, '[TRACE]')} - ${LOG_PREFIX.MESSAGE}:${message}`, LOGGER.TRACE);
    }
};
const logError = (error, context) => {
    if (currentLogLevel >= LOGGING_LEVEL.error) {
        writeToLogger(`${format(context, '[ERROR]')} - !${LOG_PREFIX.ERROR}!${LOG_PREFIX.MESSAGE}:${error.message}`, LOGGER.ERROR);
    }
};
const setWebexLogger = (logger) => {
    if (logger) {
        webexLogger = logger;
    }
};
export default {
    log: logMessage,
    error: logError,
    info: logInfo,
    warn: logWarn,
    trace: logTrace,
    setLogger,
    getLogLevel,
    setWebexLogger,
};
